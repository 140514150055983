import { AuthorizationHeader } from '@finder/cognito-react'

import { Environment, getEnvironment } from '@slmpserv/environment'

import { Configuration, Middleware, PurchaseRequestSettingsApi, RequestContext } from '../../api'

class PpnMiddleware implements Middleware {
    private tokenFunction?: () => Promise<AuthorizationHeader | undefined>

    public setTokenFunction(tokenFunction: () => Promise<AuthorizationHeader | undefined>) {
        this.tokenFunction = tokenFunction
    }

    pre = async ({ url, init }: RequestContext) => {
        if (this.tokenFunction) {
            const existingHeaders = init.headers || {}
            const headersWithAuth = {
                ...existingHeaders,
                ...(await this.tokenFunction()),
            }
            const initWithAuth = { ...init, headers: headersWithAuth }
            return { init: initWithAuth, url }
        }
        return { url, init }
    }
}

class BasePathMiddleware implements Middleware {
    private env: Environment = Environment.LOCAL
    public setEnv = (env: Environment) => (this.env = env)
    pre = async ({ url, init }: RequestContext) => {
        const backendUrl = new URL(purchaseRequestSettingsBackendUrls[this.env])
        const requestUrl = new URL(url)
        requestUrl.hostname = backendUrl.hostname
        requestUrl.protocol = backendUrl.protocol
        requestUrl.port = backendUrl.port
        return { init, url: requestUrl.toString() }
    }
}

export const apiMiddleware = new PpnMiddleware()
export const basePathMiddleware = new BasePathMiddleware()

export const purchaseRequestSettingsBackendUrls: Record<Environment, string> = {
    DEV: 'https://prs.dev.purchase-dev.pdcp.porsche-preview.digital',
    PREVIEW: 'https://prs.preview.purchase-dev.pdcp.porsche-preview.digital',
    PROD: 'https://prs.prod.purchase.pdcp.porsche.digital',
    LOCAL: 'https://prs.dev.purchase-dev.pdcp.porsche-preview.digital',
    E2E: 'https://prs.dev.purchase-dev.pdcp.porsche-preview.digital',
}

const purchaseRequestSettingsConfig = new Configuration({
    basePath: purchaseRequestSettingsBackendUrls[getEnvironment()],
    middleware: [apiMiddleware, basePathMiddleware],
})

export const purchaseRequestSettingsApi = new PurchaseRequestSettingsApi(purchaseRequestSettingsConfig)
